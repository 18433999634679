body {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 24px;
  padding-left: 0px;
}

.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 35px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #209fbd;
  background-color: #156a8f !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}

.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  width: 40%;
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#209fbd, #209fbd);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #209fbd;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.f-logo {
  width: 40%;
}
.Footer1 img {
  margin-top: 40px;
  width: 50%;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.HeroSection {
  position: relative;
  background-color: hsl( 206deg 73.23% 85.84% / 100% );
  overflow: hidden; /* Ensures no overflow from pseudo-elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8%; /* Adjust padding as needed */
  padding-top: 12%;
  padding-bottom: 0;
  gap: 100px;
}

/* Additional styling for content */
.content {
  z-index: 1; /* Ensures content is above pseudo-elements */
  padding-left: 20px; /* Adjust as needed */
  width: 60%;
}
.content h1 {
  font-size: 3rem;
  margin: 10px 0px;
  color: #e20001;
}
.content h3 {
  font-size: 1.5rem;
  margin: 0px;
  color: #212121d1;
}
.content p {
  font-size: 1.2rem;
  margin: 10px 0px;
}

.image-container {
  width: 40%; /* Adjust based on actual image dimensions */
  z-index: 1; /* Ensures image is above pseudo-elements */
  z-index: 1;
  /* background-color: #209fbd; */
  /* border-radius: 50%; */
  height: 580px;
  width: 680px;
  position: relative;
}
.image-container img {
  /* width: 100%; */
  height: 630px;
  top: -55px;
  position: absolute;
  right: 25px;
  border-radius: 50%;
}
.WhyChooseUs {
  padding: 2% 8%;
  background-color: hsl( 206deg 73.23% 85.84% / 100% );
  position: relative;
  z-index: -1;
}
.choose-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  position: relative;
  z-index: 1;
}
.HeroSection br{
  display: none;
}
.choose-img,
.choose-content {
  width: 50%;
}
.choose-img {
  text-align: left;
}
.choose-flex h1 {
  font-size: 2.3rem;
  margin: 10px 0px;
  color: #e20001;
}
.choose-flex h3 {
  font-size: 1.4rem;
  margin: 0px;
  color: #212121d1;
  max-width: 450px;
}
.choose-flex p {
  font-size: 1.2rem;
  margin: 10px 0px;
}

.choose-img img {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  min-height: 500px;
  mix-blend-mode: screen;
  object-fit: contain;
  border-radius: 50%;
}
.medicare-section {

  padding: 2% 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.medicare-section .content {
  width: 50%;

}
.medicare-section .img {
  width: 50%;

}
.medicare-section .img img{
  width: 100%;
  border-radius: 16px;

}

.medicare-section h1 {
  font-size: 2.3rem;
  margin: 10px 0px;
  color: #e20001;
}

.medicare-section p {
  font-size: 1.2rem;
  margin: 10px 0px;
}

.medicare-section ul {
  list-style: none;
  padding: 0;
}
.medicare-section ul p{
  display: flex;
  margin-left: 10px;
  
}

.medicare-section li {
  margin: 10px 20px;
  font-size: 1.2rem;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.icon {
  color: #209fbd;
  margin-right: 5px;
  margin-top: 5px;
 
}
.medicare-support {


  padding: 2% 8%; /* Ample padding for aesthetic spacing */
  font-family: 'Arial', sans-serif; /* Clear, professional font */
}

.medicare-support .container {
  max-width: 800px;
  margin: 0 auto; /* Centering the container */
  text-align: center; /* Center aligning the text */
}

.medicare-support h1 {
  font-size: 28px; /* Large font size for heading */
  font-weight: bold; /* Bold font weight for emphasis */
  margin-bottom: 10px; /* Spacing between heading and subheading */
}

.medicare-support h2 {
  font-size: 24px; /* Slightly smaller font size than the heading */
  color: #007bff; /* A different color to differentiate from the main heading */
  margin-bottom: 20px; /* Spacing between subheading and paragraph */
}

.medicare-support p {
  font-size: 16px; /* Standard reading font size */
  line-height: 1.5; /* Line height for better readability */
  margin-bottom: 20px; /* Spacing between paragraphs */
}
.testimonial-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8%;
  background-color: hsl( 206deg 73.23% 85.84% / 100% );

}

.testimonial-container {
  display: flex;

  width: 100%; /* Ensures it doesn't exceed the max width */
}

.testimonial-content {
  flex: 1;
  padding-right: 20px;
}

.testimonial-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.testimonial-image img {
  width: 100%;
  height: auto;
  max-width: 600px; /* Adjust based on your design needs */
}



.testimonial-section h1 {
  font-size: 2.3rem;
  margin: 10px 0px;
  color: #e20001;
}
.testimonial-section h3 {
  font-size: 1.4rem;
  margin: 0px;
  color: #212121d1;
  max-width: 450px;
}
.testimonial-section p {
  font-size: 1.2rem;
  margin: 10px 0px;
}
.o{
  /* padding: 8%;
  padding-top: 10%;
  padding-bottom: 0%; */
}
.o h1{
  color: #e20001;
}
.o h2{
  color: #e20001;
}
.o h3{
  color: #e20001;
}
.o h4{
  color: #e20001;
}
.o h5{
  color: #e20001;
}
.o h6{
  color: #e20001;
}
.o strong{
  color: #e20001;
}
.o b{
  color: #e20001;
}
.o a{
  color: #e20001;
  text-decoration: none;
}
.mb{
  display: none;
}
.Contact br{
  display: none;
}

.o section{
  padding: 0% 8%;
  padding-top: initial;
}
.bb{
  background-color: #156a8f;
  padding-top: 8%!important;
  padding-bottom: 2%!important;
}
.bb p{
  color: white;
}
@media (max-width: 768px) {
  .Navbar {
    display: none;
  }

  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }

  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #209fbd;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #209fbd; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #209fbd; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 7s; /* Duration of the shine animation */
  }
.Contact br{
  display: block;
}
  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 90%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }

  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }

  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }

  .btn-group {
    padding: 20px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .topFixed img{
    width: 60%;
  }
  .HeroSection{
    flex-direction: column;
    gap: 40px;
  }
  .content {
    z-index: 1;
    padding-left: 0px;
    width: 100%;
}

.image-container{
  width: 100%;
  height: 320px;

}
.image-container img{
  width: 100%;
  height: 370px;
  top: -55px;
  position: absolute;
  right: 0px;
  border-radius: 50%;
}
.HeroSection .content h1{
  font-size: 2rem;
  text-align: center;
}
.HeroSection .content h3{
  text-align: center;
}
.medicare-section{
  flex-direction: column;
  gap: 20px;
}
.medicare-section .content{
  width: 100%;
}
.medicare-section .img{
  width: 100%;
}
.medicare-section h1{
  font-size: 1.8rem;
  text-align: center;
}
.choose-flex{
  flex-direction: column-reverse;
  gap: 20px;
}
.mb{
  display: block;
  width: 100%;
}.image-container{
  display: none;
}
.choose-img, .choose-content {
  width: 100%;
}
.choose-img img {
  max-width: 500px;
  max-height: 464px;
  width: 100%;
  min-height: 348px;
  mix-blend-mode: screen;
  object-fit: cover;
  border-radius: 50%;
}
.testimonial-container{
  flex-direction: column-reverse;
}
.testimonial-section h1{
  font-size: 1.8rem;
  text-align: center;
}
.testimonial-section h2{
  text-align: center;
}
.testimonial-section{
  padding: 0% 8%;
}
.choose-flex h1{
  font-size: 1.8rem;
  text-align: center;
}
.choose-flex h3{
  text-align: center;
}
.content h3{
  text-align: center;
}
.HeroSection br{
  display: block;
}
}
